const fr = {
  account: 'Compte',
  add: 'Ajouter',
  addMember: 'Ajouter un membre',
  advanced: 'Avancé',
  allObjectives: 'Tous les objectifs',
  allProjects: 'Tous les projets',
  allTasks: 'Toutes les tâches',
  archive: 'Archiver',
  archivedObjectives: 'Objectifs archivés',
  archivedProjects: 'Projets archivés',
  archivedTasks: 'Tâches archivées',
  assignee: 'Responsable',
  billing: 'Facturation',
  blocked: 'Bloqué',
  boolean: 'Booléen',
  cancel: 'Annuler',
  censoredWords: 'Mots censurés',
  color: 'Couleur',
  comment: 'Commentaire',
  comments: 'Commentaires',
  completed: 'Completé',
  completeObjectives: 'Objectifs complétés',
  completeProjects: 'Projets complétés',
  completeTasks: 'Tâches complétées',
  completion: 'Complétion',
  create: 'Créer',
  createAnAccount: 'Créer un compte',
  createAnActivity: 'Créer une activité',
  createAnEntity: 'Créer une entité',
  createAnItem: 'Créer un item',
  createAnObjective: 'Créer un objectif',
  createAProject: 'Créer un projet',
  createASection: 'Créer une section',
  createATask: 'Créer une tâche',
  createATemplate: 'Créer un modèle',
  creditCardCvc: 'CVC',
  creditCardExpiration: 'Date d\'expiration',
  creditCardName: 'Nom du détenteur',
  creditCardNumber: 'Numéro de carte de crédit',
  dashboard: 'Tableau de bord',
  day: 'Jour',
  deadline: 'Échéance',
  delete: 'Supprimer',
  deleted: 'Supprimé',
  description: 'Description',
  details: 'Détails',
  discussion: 'Discussion',
  documentation: 'Documentation',
  doing: 'En cours',
  duplicate: 'Dupliquer',
  dueDate: 'Date d\'échéance',
  duration: 'Durée',
  edit: 'Modifier',
  emailAddress: 'Adresse courriel',
  end: 'Fin',
  entities: 'Entités',
  estimatedCompletionTime: 'Temps de complétion estimé',
  exportToCsv: 'Exporter en CSV',
  feedback: 'Votre opinion',
  fields: 'Champs',
  general: 'Général',
  history: 'Historique',
  home: 'Accueil',
  hour: 'Heure',
  icon: 'Icône',
  incompleteTasks: 'Tâches non complétées',
  informations: 'Informations',
  invalidConnection: 'Connexion invalide',
  invalidSubmission: 'Soumission invalide',
  items: 'Items',
  language: 'Langue',
  login: 'Connexion',
  loginWithGoogle: 'Connexion avec Google',
  logout: 'Déconnexion',
  members: 'Membres',
  month: 'Mois',
  myOrganization: 'Mon organisation',
  name: 'Nom',
  no: 'Non',
  notifications: 'Notifications',
  number: 'Nombre',
  objectives: 'Objectifs',
  ongoingObjectives: 'Objectifs en cours',
  ongoingProjects: 'Projets en cours',
  or: 'ou',
  organizations: 'Organisations',
  password: 'Mot de passe',
  pay: 'Payer',
  payments: 'Paiements',
  privacy: 'Confidentialité',
  progress: 'Progrès',
  project: 'Projet',
  projects: 'Projets',
  projectsDueForToday: 'Projets dûs pour aujourd\'hui',
  projectsDueForThisWeek: 'Projets dûs pour cette semaine',
  recurrence: 'Récurrence',
  required: 'Requis',
  saturday: 'Samedi',
  save: 'Sauvegarder',
  settings: 'Paramètres',
  start: 'Début',
  startDate: 'Date de début',
  stats: 'Statistiques',
  status: 'Statut',
  sunday: 'Dimanche',
  task: 'Tâche',
  tasks: 'Tâches',
  tasksDueForToday: 'Tâches dûes pour aujourd\'hui',
  tasksDueForThisWeek: 'Tâches dûes pour cette semaine',
  template: 'Modèle',
  templates: 'Modèles',
  termsOfService: 'Conditions d\'utilisation',
  text: 'Texte',
  time: 'Temps',
  timeLeft: 'Temps restant',
  timesheet: 'Feuille de temps',
  type: 'Type',
  workload: 'Charge de travail',
  xSelectedActivities: '{{count}} activités sélectionnées',
  xSelectedEntities: '{{count}} entités sélectionnées',
  xSelectedItems: '{{count}} items sélectionnés',
  xSelectedObjectives: '{{count}} objectifs sélectionnés',
  xSelectedProjects: '{{count}} projets sélectionnés',
  xSelectedTasks: '{{count}} tâches sélectionnées',
  week: 'Semaine',
  yes: 'Oui',
  youHaveNoOverdueProjects: 'Vous n\'avez aucun projet en retard.',
  youHaveXOverdueProjects: 'Vous avez {{count}} projets en retard.',
  youHaveNoOverdueTasks: 'Vous n\'avez aucune tâche en retard.',
  youHaveXOverdueTasks: 'Vous avez {{count}} tâches en retard.',
  youHaveXTasksWithNoDueDate: 'Vous avez {{count}} tâches sans date d\'échéance.',
  youHaveTooMuchToDo: 'Vous avez une moyenne de {{avgHours}} heures par jour à compléter avant le {{date}}.',
  youHaveTooMuchToDoNext7Days: 'Vous avez une moyenne de {{avgHours}} heures par jour à compléter dans les 7 prochains jours.',
  youHaveTooMuchToDoToday: 'Vous avez {{avgHours}} heures à compléter avant la fin de la journée.',
};

export default fr;
