export const envvars = {
  apiServer: 'https://api.fikas.io',
  googleOAuthClientId: '351854975996-sipa9jkarh9o46muoe8sk4ehjh846v1k.apps.googleusercontent.com',
  stripeConfirmationUrl: 'https://app.fikas.io/settings',
  stripePublishKey: 'pk_live_51NsEz8KsWRKy5brs9jgTxeVr3QBqxX9ibkrKEh11Ec7EsL57sJxLXMVoTmXUgw3TkT4Aj5XqnxPnDcuh3nviMglv00v3bl46s6',
  urlDocumentation: 'https://fikas.io/docs/intro',
  urlPrivacy: 'https://fikas.io/privacy',
  urlToS: 'https://fikas.io/tos',
  urlWebsite: 'https://fikas.io',
  websocketServer: 'wss://api.fikas.io/',
};

export default envvars;
