const en = {
  account: 'Account',
  add: 'Add',
  addMember: 'Add a member',
  advanced: 'Advanced',
  allObjectives: 'All Objectives',
  allProjects: 'All Projects',
  allTasks: 'All Tasks',
  archive: 'Archive',
  archivedObjectives: 'Archived Projects',
  archivedProjects: 'Archived Projects',
  archivedTasks: 'Archived Tasks',
  assignee: 'Assignee',
  billing: 'Billing',
  blocked: 'Blocked',
  boolean: 'Boolean',
  cancel: 'Cancel',
  censoredWords: 'Censored words',
  color: 'Color',
  comment: 'Comment',
  comments: 'Comments',
  completed: 'Completed',
  completeObjectives: 'Complete Objectives',
  completeProjects: 'Complete Projects',
  completeTasks: 'Complete Tasks',
  completion: 'Completion',
  create: 'Create',
  createAnAccount: 'Create an Account',
  createAnActivity: 'Create an Activity',
  createAnEntity: 'Create an Entity',
  createAnItem: 'Create an Item',
  createAnObjective: 'Create an Objective',
  createAProject: 'Create a Project',
  createASection: 'Create a Section',
  createATask: 'Create a Task',
  createATemplate: 'Create a Template',
  creditCardCvc: 'CVC',
  creditCardExpiration: 'Expiration Date',
  creditCardName: 'Cardholder Name',
  creditCardNumber: 'Credit Card Number',
  dashboard: 'Dashboard',
  day: 'Day',
  deadline: 'Deadline',
  delete: 'Delete',
  deleted: 'Deleted',
  description: 'Description',
  details: 'Details',
  discussion: 'Discussion',
  documentation: 'Documentation',
  doing: 'Doing',
  duplicate: 'Duplicate',
  dueDate: 'Due Date',
  duration: 'Duration',
  edit: 'Edit',
  emailAddress: 'Email address',
  end: 'End',
  entities: 'Entities',
  estimatedCompletionTime: 'Estimated Completion Time',
  exportToCsv: 'Export to CSV',
  feedback: 'Feedback',
  fields: 'Fields',
  general: 'General',
  history: 'History',
  home: 'Home',
  hour: 'Hour',
  icon: 'Icon',
  incompleteTasks: 'Incomplete Tasks',
  informations: 'Informations',
  invalidConnection: 'Invalid connection',
  invalidSubmission: 'Invalid submission',
  items: 'Items',
  language: 'Language',
  login: 'Log in',
  loginWithGoogle: 'Log in with Google',
  logout: 'Logout',
  members: 'Members',
  month: 'Month',
  myOrganization: 'My organization',
  name: 'Name',
  no: 'No',
  notifications: 'Notifications',
  number: 'Number',
  objectives: 'Objectives',
  ongoingObjectives: 'Ongoing Objectives',
  ongoingProjects: 'Ongoing Projects',
  or: 'or',
  organizations: 'Organizations',
  password: 'Password',
  pay: 'Pay',
  payments: 'Payments',
  privacy: 'Privacy',
  progress: 'Progress',
  project: 'Project',
  projects: 'Projects',
  projectsDueForToday: 'Projects Due for Today',
  projectsDueForThisWeek: 'Projects Due for this Week',
  recurrence: 'Recurrence',
  required: 'Required',
  saturday: 'Saturday',
  save: 'Save',
  settings: 'Settings',
  sunday: 'Sunday',
  start: 'Start',
  startDate: 'Start Date',
  stats: 'Stats',
  status: 'Status',
  task: 'Task',
  tasks: 'Tasks',
  tasksDueForToday: 'Tasks Due for Today',
  tasksDueForThisWeek: 'Tasks Due for this Week',
  template: 'Template',
  templates: 'Templates',
  termsOfService: 'Terms of Service',
  text: 'Text',
  time: 'Time',
  timeLeft: 'Time Left',
  timesheet: 'Timesheet',
  type: 'Type',
  xSelectedActivities: '{{count}} selected activities',
  xSelectedEntities: '{{count}} selected entities',
  xSelectedItems: '{{count}} selected items',
  xSelectedObjectives: '{{count}} selected objectives',
  xSelectedProjects: '{{count}} selected projects',
  xSelectedTasks: '{{count}} selected tasks',
  week: 'Week',
  workload: 'Workload',
  yes: 'Yes',
  youHaveNoOverdueProjects: 'You have no overdue projects.',
  youHaveXOverdueProjects: 'You have {{count}} overdue projects.',
  youHaveNoOverdueTasks: 'You have no overdue tasks.',
  youHaveXOverdueTasks: 'You have {{count}} overdue tasks.',
  youHaveXTasksWithNoDueDate: 'You have {{count}} tasks with no due date.',
  youHaveTooMuchToDo: 'You have an average of {{avgHours}} hours per day to complete before {{date}}.',
  youHaveTooMuchToDoNext7Days: 'You have an average of {{avgHours}} hours per day to complete in the next 7 days.',
  youHaveTooMuchToDoToday: 'You have {{avgHours}} hours to complete before the end of the day.',
};

export default en;
